<template>
  <div class="container_press" id="press">
    <b-container>
      <b-row>
        <b-col>
          <h1>Press</h1>
            <b-card-group deck>
              <b-container class="p-0">
                <b-row class="p-0">
                  <b-col cols="12" md="6" xl="4" class="p-0 mb-5 mb-md-4 mb-lg-4" v-for="(value, key) in posts" :key="key">
                  <a :href="value.project.url_base+'/news-room/'+value.slug" target="_blank">
                    <b-card class="container_press__column1--card h-90">
                      <div class="img-card">
                        <b-img :src="value.image" fluid :alt="value.title"></b-img>
                      </div>
                      <h4>
                        {{ (value.title.length > 100) ? value.title.substr(0, 100) + '...' : value.title }}
                      </h4>
                      <p>
                        {{ (value.subtitle && value.subtitle.length > 130) ? value.subtitle.substr(0, 130) + '...' : value.subtitle }}
                      </p>
                      <template #footer>
                        <small style="color: #6c757d;">{{value.post_date_format}}</small>
                        <b-button pill size="sm">Read more</b-button>
                      </template>
                    </b-card>
                  </a>
                    <div class="featured_ons">
                      <a v-for="(value, key) in value.featured_posts" :key="key" :href="value.link" target="_blank" class="text-decoration-none">
                        <b-avatar :src="addLogo(value.link)" :text="addAvatar(value.link)"></b-avatar>
                      </a>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-group>
        </b-col>
      </b-row>
      <div ref="infinitescrolltrigger" id="scroll-trigger"></div>
      <div v-if="showLoader" class="container_contact_us__overlay" style="left: 0;">
        <b-overlay :show="showLoader" rounded="sm">
        </b-overlay>
      </div>
    </b-container>

    <b-modal v-model="readMore" hide-footer size="lg" id="modal_press">
      <template #modal-header="{close}">
        <b-button variant="default" @click="close">
          <vue-fontawesome icon="angle-double-left" size="2"></vue-fontawesome>
        </b-button>
        <h1>Press</h1>
      </template>
        <h2>{{title}}</h2>
        <b-img :src="image" fluid center></b-img>
        <h3>{{subtitle}}</h3>
        <div v-html="body"></div>
    </b-modal>

  </div>
</template>

<script>

export default {
  data() {
    return {
      featured_ons: [],
      posts: [],
      load: true,
      showLoader: false,
      readMore: false,
      page: 1,
      totalPosts: 0,
      title: null,
      subtitle: null,
      image: null,
      body: null,
    };
  },
  mounted(){
    this.getFeaturedOns();
    this.getPosts();
    this.scrollTrigger();
  },
  methods: {
    scrollTrigger() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            if(this.posts.length < this.totalPosts) {
              this.page += 1;
              this.showLoader = true;
              this.getPosts();
            }
          }
        });
      });
      observer.observe(this.$refs.infinitescrolltrigger);
    },
    getFeaturedOns(){
      this.$http.get('featured_ons').then((response) => {
        if (response.status === 200) {
          this.featured_ons = response.body.data;
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getPosts(){
      this.$http.get('posts', {params: {paginate: 6, page: this.page}}).then((response) => {

        this.totalPosts = response.body.data.pagination.total;

        if (response.body.data.data.length) {
          this.posts = this.posts.concat(response.body.data.data);
          this.showLoader = false;
        } else {
          this.showLoader = false;
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
  }
};
</script>
