<template>
  <b-container fluid class="container_our_creations" id="our_creations">
    <b-row align-v="center" align-h="center">
      <b-col cols="12" class="text-center">
        <h1>
          Our Creations
        </h1>
      </b-col>
    </b-row>
    <swiper ref="swiper" :options="swiperOptions" class="swiper-services">
      <!-- MCSS -->
      <swiper-slide active>
        <b-container fluid>
          <b-row align-v="center" align-h="center">
            <b-col cols="12" lg="5" order="2" order-lg="1" class="content_our_creations">
              <h3>MCSS</h3>
              <p>
                Create complex graphic designs in Android and iOS keeping the same aesthetics on both
                platforms cutting development time and costs by more than 40%.
                One MCSS instruction replaces several lines of code in Android and iOS simultaneously.
              </p>
              <b-button pill class="mt-md-2" href="https://www.getmcss.com/" target="_blank">
                Go to MCSS
              </b-button>
            </b-col>
            <b-col cols="12" lg="5" class="mt-3 mt-md-0" order="1" order-lg="2">
              <div class="mcss-pc"></div>
            </b-col>
          </b-row>
        </b-container>
      </swiper-slide>
      <!-- ICON GALLERY -->
      <swiper-slide>
        <b-container fluid>
          <b-row align-v="center" align-h="center">
            <b-col cols="12" lg="5" order="2" order-lg="1" class="content_our_creations content">
              <h3>Icon gallery</h3>
              <p>
                Find a complete library/gallery of icons, essential for the creation of
                functions in the development of your apps.
              </p>
              <b-button pill class="mt-md-2" href="https://docs.getmcss.com/mcss-icons" target="_blank">
                Go to icon gallery
              </b-button>
            </b-col>
            <b-col cols="12" lg="5" class="mt-3 mt-md-0" order="1" order-lg="2">
              <div class="mcss-icons"></div>
            </b-col>
          </b-row>
        </b-container>
      </swiper-slide>
      <!-- DOCS -->
      <swiper-slide>
        <b-container fluid>
          <b-row align-v="center" align-h="center">
            <b-col cols="12" lg="5" order="2" order-lg="1" class="content_our_creations content">
              <h3>Docs</h3>
              <p>
                Read and learn technical documents, manuals, guides and instructions to make the most of
                MCSS potential’s at the time of development.
              </p>
              <b-button pill class="mt-md-2" href="https://docs.getmcss.com/how-mcss-works" target="_blank">
                Go to docs
              </b-button>
            </b-col>
            <b-col cols="12" lg="5" class="mt-3 mt-md-0" order="1" order-lg="2">
              <div class="mcss-docs"></div>
            </b-col>
          </b-row>
        </b-container>
      </swiper-slide>
      <!-- THEMES -->
      <swiper-slide>
        <b-container fluid>
          <b-row align-v="center" align-h="center">
            <b-col cols="12" lg="5" order="2" order-lg="1" class="content_our_creations content">
              <h3>Themes</h3>
              <p>
                Predesigned mobile app themes built with MCSS to use and quickly change graphic components
                and aesthetics to adapt to any mobile app design in Android and iOS by simple CSS instructions.
              </p>
              <b-button pill class="mt-md-2" href="https://docs.getmcss.com/mcss-themes" target="_blank">
                Go to themes
              </b-button>
            </b-col>
            <b-col cols="12" lg="5" class="mt-3 mt-md-0" order="1" order-lg="2">
              <div class="mcss-themes"></div>
            </b-col>
          </b-row>
        </b-container>
      </swiper-slide>
      <!-- COMMUNITY -->
      <swiper-slide>
        <b-container fluid>
          <b-row align-v="center" align-h="center">
            <b-col cols="12" lg="5" order="2" order-lg="1" class="content_our_creations content" align-self="center">
              <h3>Community</h3>
              <p>
                Meet and reach out to fellow mobile developers for technical questions and help others by
                sharing your knowledge and best practices, through a space created for the MCSS developer
                community at a global level.
              </p>
              <b-button pill class="mt-md-2" href="https://community.getmcss.com" target="_blank">
                Go to community
              </b-button>
            </b-col>
            <b-col cols="12" lg="5" class="mt-3 mt-md-0" order="1" order-lg="2">
              <div class="mcss-community"></div>
            </b-col>
          </b-row>
        </b-container>
      </swiper-slide>
      <!-- API MONITOR -->
      <swiper-slide>
        <b-container fluid>
          <b-row align-v="center" align-h="center">
            <b-col cols="12" lg="5" order="2" order-lg="1" class="content_our_creations content_api_monitor">
              <h3>Api Monitor</h3>
              <p>
                We just think API Monitors don’t need to be so expensive. Stay tuned, Coming Soon!
              </p>
            </b-col>
            <b-col cols="12" lg="5" class="mt-3 mt-md-0" order="1" order-lg="2">
              <div class="img-api-monitor api-monitor"></div>
            </b-col>
          </b-row>
        </b-container>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </b-container>
</template>

<script>
export default {
  props: {
    option: {
      type: String(),
    },
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  watch: {
    option: function(option) {
      switch (option) {
        case 'mcss':
          this.swiper.slideTo(0, 1200, false);
          break;
        case 'community':
          this.swiper.slideTo(4, 1200, false);
          break;
        case 'icons':
          this.swiper.slideTo(1, 1200, false);
          break;
        case 'docs':
          this.swiper.slideTo(2, 1200, false);
          break;
        case 'themes':
          this.swiper.slideTo(3, 1200, false);
          break;
        case 'api':
          this.swiper.slideTo(5, 1200, false);
          break;
      }
    },
  },
};
</script>
