<template>
  <b-container fluid class="container_services">
    <b-row>

      <!-- MCSS -->
      <b-col cols="12" md class="p-0 container_services__service">
        <div class="container_services__service text-center">

          <div class="mcss-prev d-none d-md-block"></div>
          <div class="mcss-prev-mobile d-md-none"></div>

          <div class="container_services__service--overlay
                      container_services__service--overlay-yellow
                      text-center">

            <b-row class="m-0">
              <b-col class="text-white">
                <h1>MCSS</h1>
                <b-button pill class="container_services__service--btn-yellow" @click="selectOption('mcss')">
                  DISCOVER
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <!-- COMMUNITY -->
      <b-col cols="6" md class="p-0 container_services__service">
        <div class="container_services__service text-center">

            <div class="community-prev d-none d-md-block"></div>
            <div class="community-prev-mobile d-md-none"></div>

          <div class="container_services__service--overlay
                      container_services__service--overlay-orange
                      text-center">
            <b-row class="m-0" align-v="end">
              <b-col class="text-white">
                <h1>COMMUNITY</h1>
                <b-button pill class="container_services__service--btn-orange" @click="selectOption('community')">
                  REACH
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <!-- ICONS -->
      <b-col cols="6" md class="p-0 container_services__service">
        <div class="container_services__service text-center">

            <div class="icons-prev d-none d-md-block"></div>
            <div class="icons-prev-mobile d-md-none"></div>

          <div class="container_services__service--overlay
                      container_services__service--overlay-fuchsia
                      text-center">
            <b-row class="m-0" align-v="end">
              <b-col cols="12" class="text-white">
                <h1>ICONS</h1>
                <b-button pill class="container_services__service--btn-fuchsia" @click="selectOption('icons')">
                  EXPLORE
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <!-- DOCS -->
      <b-col cols="6" md class="p-0 container_services__service">
        <div class="container_services__service text-center">

            <div class="docs-prev d-none d-md-block"></div>
            <div class="docs-prev-mobile d-md-none"></div>

          <div class="container_services__service--overlay
                      container_services__service--overlay-cyan
                      text-center">
            <b-row class="m-0" align-v="end">
              <b-col class="text-white">
                <h1>DOCS</h1>
                <b-button pill class="container_services__service--btn-cyan" @click="selectOption('docs')">
                  LEARN
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <!-- THEMES -->
      <b-col cols="6" md class="p-0 container_services__service">
        <div class="container_services__service text-center">

            <div class="themes-prev d-none d-md-block"></div>
            <div class="themes-prev-mobile d-md-none"></div>

          <div class="container_services__service--overlay
                      container_services__service--overlay-darkblue
                      text-center">
            <b-row class="m-0" align-v="end">
              <b-col class="text-white">
                <h1>THEMES</h1>
                <b-button pill class="container_services__service--btn-darkblue" @click="selectOption('themes')">
                  TRY
                </b-button>
              </b-col>
            </b-row>
          </div>

        </div>
      </b-col>

  </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Services',
  methods: {
    selectOption(option) {
      this.$emit('selectedOption', option);
      if(this.$route.hash != '#our_creations'){
        this.$router.push({ name: 'home', hash: '#our_creations' });
      } else {
        this.$router.replace('/');
        this.$router.push({ name: 'home', hash: '#our_creations' });
      }
    }
    /* getContent(path, external) {
      if (external) {
        window.open(path, '_blank');
      } else if (this.$route.name !== path) {
        this.$router.push({ name: path });
      }
    }, */
  },
};
</script>
