<template>
  <div class="container_contact_us" id="contact_us">
    <div class="container_contact_us__title">
      <h1>
        CONTACT <br>
        US
      </h1>
    </div>

    <div class="container_contact_us__form">
      <b-form action="#">

        <!-- Name -->
        <b-form-group class="mb-4">
          <b-form-input type="text" placeholder="Name"
                        :class="{'is-invalid': $v.form.name.$error}"
                        v-model.trim="$v.form.name.$model"></b-form-input>

          <span role="alert" class="invalid-feedback" v-if="$v.form.name.$error">
            <strong v-if="!$v.form.name.required">
              The Name is required
            </strong>
            <strong v-if="!$v.form.name.minLength">
              The Name must have at least {{$v.form.name.$params.minLength.min}} letters
            </strong>
            <strong v-if="!$v.form.name.maxLength">
              The Name must be less than {{$v.form.name.$params.maxLength.max}} letters
            </strong>
          </span>
        </b-form-group>

        <!-- Phone -->
        <b-form-group class="mb-4">
          <b-form-input type="text" placeholder="Phone"
                        :class="{'is-invalid': $v.form.phone.$error}"
                        v-model.trim="$v.form.phone.$model"></b-form-input>

          <span role="alert" class="invalid-feedback" v-if="$v.form.phone.$error">
            <strong v-if="!$v.form.phone.required">
              The Phone is required
            </strong>
            <strong v-if="!$v.form.phone.numeric">
              The Phone must be numeric
            </strong>
            <strong v-if="!$v.form.phone.minLength">
              The Phone must have at least {{$v.form.phone.$params.minLength.min}} numbers
            </strong>
            <strong v-if="!$v.form.phone.maxLength">
              The Phone must be less than {{$v.form.phone.$params.maxLength.max}} numbers
            </strong>
          </span>
        </b-form-group>

        <!-- Email -->
        <b-form-group class="mb-4">
          <b-form-input type="email" placeholder="Email"
                        :class="{'is-invalid': $v.form.email.$error}"
                        v-model.trim="$v.form.email.$model"></b-form-input>

          <span role="alert" class="invalid-feedback" v-if="$v.form.email.$error">
              <strong v-if="!$v.form.email.required">The Email is required</strong>
              <strong v-if="!$v.form.email.email">Invalid Email format</strong>
              <strong v-if="!$v.form.email.maxLength">
                The Email must be less than {{$v.form.email.$params.maxLength.max}} letters
              </strong>
          </span>
        </b-form-group>

        <!-- Message -->
        <b-form-group class="mb-4">
          <b-form-textarea placeholder="Message" rows="6"
                          :class="{'is-invalid': $v.form.message.$error}"
                           v-model.trim="$v.form.message.$model"></b-form-textarea>

          <span role="alert" class="invalid-feedback" v-if="$v.form.message.$error">
            <strong v-if="!$v.form.message.required">
              The Message is required
            </strong>
            <strong v-if="!$v.form.message.minLength">
              The Message must have at least {{$v.form.message.$params.minLength.min}} letters
            </strong>
            <strong v-if="!$v.form.message.maxLength">
              The Message must be less than {{$v.form.message.$params.maxLength.max}} letters
            </strong>
          </span>
        </b-form-group>

        <!-- Recaptcha -->
        <b-form-group>
          <b-row>
            <b-col cols="12" lg="8">
              <vue-recaptcha sitekey="6LcQgDsdAAAAAK2LJ7suFj_885vYPcyilUYPtkjH"
                            @verify="validateRecaptcha" @expired="validateRecaptcha"
                            :class="{'is-invalid': $v.form.recaptcha.$error}"
                             v-model.trim="$v.form.recaptcha.$model"
                             ref="recaptcha"></vue-recaptcha>

              <span role="alert" class="invalid-feedback" v-if="$v.form.recaptcha.$error">
                <strong v-if="!$v.form.recaptcha.required">The Recaptcha is required</strong>
              </span>
            </b-col>
            <b-col class="text-center mt-4" cols="12" lg="4">
              <b-button pill @click="sendContactUs()">SEND</b-button>
            </b-col>
          </b-row>
        </b-form-group>

      </b-form>
    </div>

    <div v-if="load" class="container_contact_us__overlay">
      <b-overlay :show="load" rounded="sm">
      </b-overlay>
    </div>

    <transition name="fade">
      <DGOAlert v-if="alert.active" :isActive="alert.active" :typeAlert="alert.type"
                :text="alert.text" :errors="alert.errors"></DGOAlert>
    </transition>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import {
  required, email, numeric, minLength, maxLength,
} from 'vuelidate/lib/validators';
import DGOAlert from '@/components/Alert.vue';

export default {
  name: 'ContactUs',
  components: {
    VueRecaptcha,
    DGOAlert,
  },
  data() {
    return {
      form: {
        name: String(),
        phone: String(),
        email: String(),
        message: String(),
        recaptcha: null,
      },
      alert: {
        active: false,
        text: null,
        type: null,
        errors: null,
      },
      load: false,
    };
  },
  mounted() {

  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60),
      },
      phone: {
        required,
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(25),
      },
      email: {
        required,
        email,
        maxLength: maxLength(60),
      },
      message: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255),
      },
      recaptcha: { required },
    },
  },
  beforeMount() {},
  methods: {

    sendContactUs() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.$http.post('contactUs', this.form).then((response) => {
          if (response.status === 200) {
            this.showMessage(response.body.message, null, 'success');
            this.$v.form.$reset(response.body);
            this.form = {
              name: String(),
              phone: String(),
              email: String(),
              message: String(),
              recaptcha: null,
            };
            this.$refs.recaptcha.reset();
          }
          this.load = false;
        }, (error) => {
          if (error.status === 400) {
            this.showMessage(error.body.message, error.body.data, 'error');
          }
          this.load = false;
        });
      }
    },

    validateRecaptcha(response) {
      this.form.recaptcha = response;
    },

    showMessage(text, errors, type) {
      this.alert = {
        active: true,
        text,
        type,
        errors,
      };
      setTimeout(() => {
        this.alert = {
          active: false,
          text: null,
          type: null,
          errors: null,
        };
      }, 5000);
    },

  },
};
</script>
