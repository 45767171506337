<template>
  <div @focusout="focusOut()">
    <b-navbar toggleable id="nav-landing" v-if="$route.name != 'news-room.slug'">
      <b-navbar-toggle target="navbar-toggle-collapse" @click="nav2()">
        <template>
          <img v-if="expanded" src="@/assets/img/arrow-right.svg" class="img-fluid arrows" width="30">
          <vue-fontawesome v-else icon="bars"></vue-fontawesome>
        </template>
      </b-navbar-toggle>

      <b-collapse id="navbar-toggle-collapse" is-nav v-model="expanded">
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'home', hash: '#about_us' }">About Us</b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#our_creations' }">Our Creations</b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#careers' }">Careers</b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#press' }">Press</b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#contact_us' }">Contact Us</b-nav-item>
          <b-nav-item :to="{ name: 'login' }">Login</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <router-link :to="{ name: 'home' }" class="brand">
        <img src="@/assets/img/do-genius-on-brand.svg" class="img-fluid" width="100">
      </router-link>
    </b-navbar>

    <!--  -->
    <b-navbar toggleable="md" type="light" variant="light" id="nav-2" class="nav-2 d-none d-md-none">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav ref="collapseNav2" v-model="expandedNav2">
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'home', hash: '#about_us' }">
            <img src="@/assets/img/users.light.svg">
            <label :class="{ 'active': $route.hash  == '#about_us'}">About Us</label>
          </b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#our_creations' }">
            <img src="@/assets/img/favorite.light.svg">
            <label :class="{ 'active': $route.hash  == '#our_creations'}">Our Creations</label>
          </b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#careers' }">
            <img src="@/assets/img/briefcase.light.svg">
            <label :class="{ 'active': $route.hash  == '#careers'}">Careers</label>
          </b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#press' }">
            <img src="@/assets/img/file.alt.light.svg">
            <label :class="{ 'active': $route.hash  == '#press'}">Press</label>
          </b-nav-item>
          <b-nav-item :to="{ name: 'home', hash: '#contact_us' }">
            <img src="@/assets/img/mail.light.svg">
            <label :class="{ 'active': $route.hash  == '#contact_us'}">Contact Us</label>
          </b-nav-item>
          <b-nav-item>
            <router-link :to="{ name: 'login' }">
              <b-button variant="outline-dark">Login</b-button>
            </router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: 'Navbar',
  data() {
    return {
      expanded: Boolean(),
      expandedNav2: Boolean(),
    };
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'navbar-toggle-collapse') {
        this.expanded = isJustShown;
      }
    });
    this.scroll();
  },
  methods: {
    nav() {
      this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse');
    },
    nav2() {
      this.expandedNav2 = !this.expandedNav2
      document.querySelector('#nav-2').classList.remove('d-none');
    },
    scroll() {
      window.onscroll = function() {
        let nav = document.querySelector('#nav-2');
        if(nav) {
          if (window.scrollY > 80) {
            nav.classList.remove('d-none');
            nav.classList.remove('d-md-none');
            nav.classList.add('d-block');
            document.querySelector('#navbar-toggle-collapse').classList.remove('show');
          } else {
            nav.classList.remove('d-block');
            nav.classList.add('d-none');
            nav.classList.add('d-md-none');
            document.querySelector('#navbar-toggle-collapse').classList.add('show');
          }
        }
      };
    },
    focusOut() {
      this.expanded = false;
      this.expandedNav2 = false;
    }
  },
};
</script>
