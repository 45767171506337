<template>
  <div class="container_about_us" id="about_us">
    <div class="container_about_us__title">
      <h1>ABOUT US</h1>
    </div>
    <div class="container_about_us__text">
      <p>
        Do Genius On, was born from an idea to innovate, improve and enhance the development
        of native mobile applications on Android and iOS. With more than 10 years building native
        mobile applications and going through the same difficult challenges that native mobile
        developers face, such as: long development times, complexity when creating graphics, building
        two different designs and aesthetics (Android/iOS). This, for the purpose of matching the look
        and feel of Android to iOS and vice versa, maintaining two different apps at the same time, etc.
        <br><br>
        We knew we didn't want to go the easy way: sacrificing performance and user experience to save
        time and costs by developing a React app or a hybrid app. We were eager to build a solution that
        would improve and impact development times, cost, and maintenance of native mobile apps. This,
        while offering the end user the best mobile app experience they could get.
      </p>
      <p :class="{ 'd-none' : !readMore }">
        We failed and failed, many times, but we kept going. Nothing was going to stop our dream of putting
        in that extra effort to help all our fellow developers, who believe as we do that: "delivering the
        best possible product to the end user is priceless"
        <br><br>
        Long nights and long weekends without our families and friends. Brainstorming, testing, and failing,
        until one night we came up with the best amazing and crazy idea. Why not use CSS in mobile apps, but natively?
        <br><br>
        Just like CSS helps to create awesome and reliable websites, maybe we can try to apply it to native mobile
        development. And here we are, introducing MCSS (Mobile Cascade Style Sheet), a 100% free technology for all
        our fellow native mobile developers.
        <br><br>
        MCSS reduces native development time by more than 40% on Android and iOS at the same time, making native
        development more affordable while delivering the best user experience.
        <br><br>
        <a href="https://www.getmcss.com/" target="_blank">Learn more about MCSS...</a>
        <br><br>
        Now, our DGO Geniuses are dedicated to building technology for all those technology builders. trying to
        improve their quality of life, giving them back more time to spend with family and friends and less time
        facing development problems in front of a computer.
        <br><br>
        We are growing rapidly and are always looking for new talent to join our team of geniuses to continue building
        the future. We are backed by an amazing team of investors who believe in what we are doing and know the changing
        technology industry very well.
        <br><br>
        Check out our different projects and feel free to contact us if you have any questions.
        <br><br>
        Let's keep building technology for technology builders!
      </p>
      <label v-if="readMore" @click="readMore = false">Read less...</label>
      <label v-else @click="readMore = true">Read more...</label>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        readMore: false,
      };
    },
  }
</script>
