<template>
  <div>
    <Navbar></Navbar>
    <Services v-on:selectedOption="selectedOption"></Services>
    <AboutUs></AboutUs>
    <OurCreations :option="this.option"></OurCreations>
    <SendCurriculum></SendCurriculum>
    <Press></Press>
    <ContactUs></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue';
import Services from '@/views/Home/Services.vue';
import AboutUs from '@/views/Home/AboutUs.vue';
import OurCreations from '@/views/Home/OurCreations.vue';
import SendCurriculum from '@/views/Home/SendCurriculum.vue';
import Press from '@/views/Home/Press.vue';
import ContactUs from '@/views/Home/ContactUs.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    Services,
    AboutUs,
    OurCreations,
    SendCurriculum,
    Press,
    ContactUs,
    Footer,
  },
  data() {
    return {
      option: String(),
    };
  },
  methods: {
    selectedOption: function (option) {
      this.option = option;
    },
  },
};

</script>
