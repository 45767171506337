<template>
    <div class="container_alert">
      <div class="container_alert__text"
           :class="['alert_'+typeAlert, {'alert_active': isActive}]">
        <b-row>
          <b-col cols="2" align-self="center">
            <img v-if="typeAlert === 'success'" src="@/assets/img/smile.svg" class="img-fluid">
            <img v-else src="@/assets/img/meh.svg" class="img-fluid">
          </b-col>
          <b-col cols="10">
            <h2 v-if="typeAlert === 'success'">Thanks!</h2>
            <h2 v-else>Sorry!</h2>
            <p v-if="typeAlert === 'success'">{{text}}</p>
            <ul v-if="typeAlert === 'error' && typeof errors === 'object'" class="mt-2">
              <li v-for="(error, index) in errors" :key="index">
                <label v-for="(data, index) in error" :key="index">
                  {{ data }}
                </label>
              </li>
            </ul>
            <label v-if="typeAlert === 'error' && typeof errors === 'string'">{{text}}</label>
          </b-col>
        </b-row>
      </div>
    </div>
</template>
<script>
export default {
  name: 'Alert',
  props: {
    typeAlert: {
      type: String,
      default: null,
      /**
       * Types:
       *  error
       *  success
       */
    },
    text: {
      type: String,
      default: 'Alert',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    errors: {
      default: null,
    },
  },
};
</script>
