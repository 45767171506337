<template>
  <div class="container_send_curriculum" id="careers">
    <div class="container_send_curriculum__img d-lg-none">
      <img src="@/assets/img/person.png" class="img-fluid">
    </div>
    <div class="container_send_curriculum__text">
      <div>
        <h1 class="container_send_curriculum__text--title">
          We are not looking for developers, we are looking
          for technology innovators!
        </h1>
        <p class="container_send_curriculum__text--text">
          But that’s not all. You have to be extra cool too and a great team player.
          If you think you are a Genius then let’s Do Genius On our next cool projects
          and submit your resume here:
        </p>
        <b-row>
          <b-col cols="12">
            <div class="input-group">
              <div class="custom-file container_send_curriculum__text--input-file">
                <b-form-file v-model="$v.form.file.$model" @change="validateSizeFile" class="custom-file-input hidden"
                  plain id="file1" accept=".jpg, .jpeg, .png, .pdf"></b-form-file>
                <label class="custom-file-label" for="file1">{{ form.file ? form.file.name : 'Attach Resume' }}</label>
                <img src="@/assets/img/clip.svg" class="img-fluid" width="14" height="14">
                <b-button pill size="sm" @click="showPopup()">SEND</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- POP-UP -->
    <b-modal id="pop-up" size="xl" hide-header hide-footer centered>
      <div class="container_popup__popup">
        <div class="container_popup__title">
          <div class="text-center">
            <h1>Fill in the information to send your form</h1>
            <vue-fontawesome icon="chevron-circle-right" class="d-none d-lg-block"></vue-fontawesome>
            <vue-fontawesome icon="chevron-circle-down" class="d-lg-none mt-2"></vue-fontawesome>
          </div>
        </div>

        <div class="container_popup__form pt-5 pb-3 pt-xl-0 pb-xl-0">
          <form @submit.prevent="SendCurriculum">
            <div class="form-group row">
              <label for="name" class="col-12 col-lg-2 col-form-label">Name <span>*</span></label>
              <div class="col-12 col-lg-10 col-xl-9">
                <input type="text" class="form-control" id="name"
                  :class="{'is-invalid': $v.form.name.$error}"
                  v-model.trim="$v.form.name.$model">

                <span role="alert" class="invalid-feedback" v-if="$v.form.name.$error">
                  <strong v-if="!$v.form.name.required">
                    The name is required
                  </strong>
                  <strong v-if="!$v.form.name.minLength">
                    The name must have at least {{$v.form.name.$params.minLength.min}} letters
                  </strong>
                  <strong v-if="!$v.form.name.maxLength">
                    The name must be less than {{$v.form.name.$params.maxLength.max}} letters
                  </strong>
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col-12 col-lg-2 col-form-label">Email <span>*</span></label>
              <div class="col-12 col-lg-10 col-xl-9">
                <input type="email" class="form-control" id="email"
                  :class="{'is-invalid': $v.form.email.$error}"
                  v-model.trim="$v.form.email.$model">

                <span role="alert" class="invalid-feedback" v-if="$v.form.email.$error">
                  <strong v-if="!$v.form.email.required">The email is required</strong>
                  <strong v-if="!$v.form.email.email">Invalid email format</strong>
                  <strong v-if="!$v.form.email.maxLength">
                    The email must be less than {{$v.form.email.$params.maxLength.max}} letters
                  </strong>
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label for="job" class="col-12 col-lg-2 col-form-label">Job offer <span>*</span></label>
              <div class="col-12 col-lg-10 col-xl-9">
                <b-form-select v-model="$v.form.job.$model" :options="options" id="job"
                  :class="{'is-invalid': $v.form.job.$error}"></b-form-select>
                <span role="alert" class="invalid-feedback" v-if="$v.form.job.$error">
                  <strong v-if="!$v.form.job.required">
                    The job offer is required
                  </strong>
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label for="file" class="col-12 col-lg-2 col-form-label">Add file <span>*</span></label>
              <div class="col-12 col-lg-10 col-xl-9">
                <div class="input-group" :class="{'is-invalid': $v.form.file.$error}">
                  <div class="custom-file container_send_curriculum__text--input-file input-file">
                    <div class="pdf">
                      <img src="@/assets/img/file-pdf.svg" class="img-fluid" width="14" height="14">
                    </div>
                    <b-form-file @change="validateSizeFile" v-model="$v.form.file.$model" plain id="file" accept=".jpg, .jpeg, .png, .pdf"></b-form-file>
                    <label class="custom-file-label" for="file">{{ form.file ? form.file.name : 'Curriculum' }}</label>
                  </div>
                </div>
                <span role="alert" class="invalid-feedback" v-if="$v.form.file.$error">
                  <strong v-if="!$v.form.file.required">
                    The file is required
                  </strong>
                </span>
              </div>
            </div>
            <div class="form-group row mt-4">
              <div class="col-12 col-lg-11 btns">
                <b-button pill size="sm" class="col-3 btn-cancel" @click="closePopup()">CANCEL</b-button>
                <b-button pill size="sm" class="col-3 btn-send" type="submit">SEND</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <div v-if="load" class="container_contact_us__overlay">
      <b-overlay :show="load" rounded="sm">
      </b-overlay>
    </div>
    <transition name="fade">
      <DGOAlert v-if="alert.active" :isActive="alert.active" :typeAlert="alert.type"
                :text="alert.text" :errors="alert.errors"></DGOAlert>
    </transition>
  </div>
</template>

<script>
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
import DGOAlert from '@/components/Alert.vue';

export default {
  name: 'SendCurriculum',
  components: {
    DGOAlert,
  },
  data() {
    return {
      form: {
        name: String(),
        email: String(),
        job: null,
        file: null,
      },
      options: [
        { value: null, text: 'Select an option' },
        { value: 'Web Developer', text: 'Web Developer' },
        { value: 'iOs Developer', text: 'iOs Developer' },
        { value: 'Android Developer', text: 'Android Developer' },
        { value: 'UI/UX Designer', text: 'UI/UX Designer' },
      ],
      alert: {
        active: false,
        text: null,
        type: null,
        errors: null,
      },
      load: false,
      isValid: false,
    };
  },
  validations: {
    form: {
      name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      email: { required, email, maxLength: maxLength(60) },
      job: { required },
      file: { required }
    },
  },
  methods: {
    SendCurriculum() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        if(this.isValid) {
          this.load = true;

          const formData = new FormData();
          formData.append('name', this.form.name);
          formData.append('email', this.form.email);
          formData.append('job', this.form.job);
          formData.append('file', this.form.file);

          this.$http.post('careers', formData).then((response) => {
            this.load = false;
            if (response.status === 200) {
              this.closePopup();
              this.showMessage(response.body.message, null, 'success');
            }
          }, (error) => {
            this.load = false;
            if (error.status === 400) {
              this.showMessage(error.body.message, error.body.data, 'error');
            }
          });
        } else {
          this.showMessage('the file weight exceeds 25MB', '', 'error');
          this.form.file = null;
        }
      }
    },
    validateSizeFile(e){
      if (e.target.files[0].size > 25000000) {
        this.showMessage('the file weight exceeds 25MB', e.target.files[0].size.toString(), 'error');
        this.form.file = null;
        this.isValid = false;
      } else {
        this.isValid = true;
        this.showPopup();
      }
    },
    showMessage(text, errors, type) {
      this.alert = {
        active: true,
        text,
        type,
        errors,
      };
      setTimeout(() => {
        this.alert = {
          active: false,
          text: null,
          type: null,
          errors: null,
        };
      }, 5000);
    },
    showPopup(){
      this.$bvModal.show('pop-up');
    },
    closePopup() {
      this.$bvModal.hide('pop-up');
      this.$v.form.$reset();
      this.form = {
        name: String(),
        email: String(),
        job: null,
        file: null,
      };
    }
  },
}
</script>
